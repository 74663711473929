import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import GaltLanding from '../components/GaltLanding'

import pic08 from '../assets/images/galt/rockland.jpg'
import pic09 from '../assets/images/galt/rockland-1320.jpg'
import pic10 from '../assets/images/galt/galthouse-1320.jpg'
import pic11 from '../assets/images/galt/rockland01.jpg'

const Galt = (props) => (
    <Layout>
        <Helmet>
            <title>Galt House - Victoria Heritage Apartments</title>
            <meta name="description" content="Galt House. Victoria British Columbia. Victoria Heritage Apartments." />
        </Helmet>

        <GaltLanding />

        <div id="main">
            <section id="one" className="spotlights">
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic11} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>About</h3>
                            </header>
                            <p>Formerly called Highwood. Eventually, it was re-named for a subsequent owner, John Galt, who commissioned Maclure to design alterations. After several years, it was eventually divided into a 9-suite apartment in June 1943 by Miss E. Cherry. Galthouse has similarities to several other Maclure creations of the same period. The iconic basic form (steep hipped roof, a horizontal emphasis given by contrasting surface treatments of the first and second floors) resembles the tudor rival mansions that Maclure's name became synonymous with.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic09} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Size & Scale</h3>
                            </header>
                            <p>With a total suite count of 12 units, Galt House is 3 stories high and is roughly 10,000 square feet in size.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic08} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Location</h3>
                            </header>
                            <p>Located at 1320 Rockland avenue, Galthouse is only a few short blocks away from beautiful downtown Victoria. Perched on a craggy hilltop, Rockland is an elegant neighbourhood known for Craigdarroch Castle, an ornate Victorian home built by coal baron Robert Dunsmuir. Grand Arts and Crafts mansions line the area’s leafy streets, while Government House, the lieutenant governor’s residence, has manicured gardens and woodland trails. Exhibits at the Art Gallery of Greater Victoria include paintings by BC artist Emily Carr.</p>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/generic" className="image">
                        <img src={pic10} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                          <header className="major">
                              <h3>Design</h3>
                          </header>
                          <p>It has a steeply-hipped roof with exposed rafter tails, many shed-roofed dormers, and one gabled dormer on the main Gillespie Pl façade. There is a large 2½-storey, half-timbered, gabled bay on the Rockland Av façade, with bracketed bargeboards and a drop finial. It has an angled bay on the main floor below an angled balcony; to the right is a large square balcony over a glassed-in main floor extension.</p>
                        </div>
                    </div>
                </section>
            </section>
        </div>

    </Layout>
)

export default Galt
