import React from 'react'

const GaltLanding = (props) => (
    <section id="galtbanner" className="style2">
        <div className="inner">
            <header className="major">
                <h1>Galthouse <span>est. 1912</span></h1>
            </header>
            <p>
              Located at 1320 Rockland avenue, this stately mansion was designed by Samuel Maclure for Frederick Nation. <br/>
               Nation, a Manitoba department store owner and director of the Great West Life Assurance Co. <br/>
              Galt House is a 2½-storey British Arts & Crafts Tudor Revival mansion; Maclure’s name became synonymous with this style.
            </p>
        </div>
    </section>
)

export default GaltLanding
